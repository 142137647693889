html,
body,
#root {
  width: 100%;
  height: 100%;
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  color: #1976d2;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  max-width: 500px;
}

.link:hover {
  color: #1663af;
  text-decoration: underline;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.percent-spacer {
  display: inline-block;
  min-width: 3.5em;
}

.fwb {
  font-weight: 500;
}

.alignr {
  text-align: right !important;
}

ul {
  list-style: none;
}
